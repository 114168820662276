@mixin mobile() {
  @media only screen and (max-width: 600px) {
    @content;
  }
}
@mixin smallTablet() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin small() {
  @media (min-width: 769px) and (max-width: 991px) {
    @content;
  }
}
@mixin belowSM() {
  @media (max-width: 991px) {
    @content;
  }
}
@mixin maxSmall() {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin XL() {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin belowXL() {
  @media (max-width: 1440px) {
    @content;
  }
}
@mixin belowMD() {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin belowLG() {
  @media (max-width: 1200px) {
    @content;
  }
}
