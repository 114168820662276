// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";

@import "../scss/keenslider.scss";
@import "../scss/swiper.scss";

.test {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25),
    -2px -2px 4px rgba(255, 187, 0, 0.25);
  color: rgb(255, 187, 0);
}

.termsList {
  li {
    font-family: "Mulish", sans-serif !important;
    font-weight: 600;
    margin-left: 0.5em;
    font-size: 0.9rem;
    color: rgb(246, 246, 246);
    padding-bottom: 0.6em;
    text-align: justify;
    list-style: none;
  }
}
