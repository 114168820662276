@import "./mixins";

.keen-carousel {
    &.small {
        [class^="headerSlide"],
        [class*="headerSlide"] {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
            height: 190px;
            min-width: 175px;
            margin: 0 4px;
         
    
   
        }
    }
    &.large {
        [class^="headerSlide"],
        [class*="headerSlide"] {
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            max-height: 75vh;


            img {
                width: 100%;
                height: auto;
                margin: 0 auto;
            }
        }
        @include mobile() {
            [class^="headerSlide"],
            [class*="headerSlide"] {
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
                max-height: 100vh;
         
                // padding-top: 40px;

                img {
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                }
            }
        }
    }
    .lazy__slide {
        height: 300px;
        background: rgb(255, 75, 64);
        background-color: linear-gradient(
          0deg,
          rgba(255, 75, 64, 1) 0%,
          rgba(255, 154, 63, 1) 100%
        );
        // background-image: url("./../../assets/img/loader.svg");
        background-position: center;
        background-repeat: no-repeat;

        min-height: auto;
  
      }
      
      .lazy__slide img {
        background-color: transparent;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
}

.navigation-wrapper {
    position: relative;
}

.dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
}

.dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
}

.dot:focus {
    outline: none;
}

.dot.active {
    background: #000;
}

.arrow {
    width: 32px;
    height: 32px;
    position: absolute;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: 'white';
    cursor: pointer;
}

.arrow--left {
    left: 10px;
    @include mobile() {
        left: 10px;
    }
}
.arrow--right {
    left: auto;
    right: 10px;
    @include mobile() {
        right: 10px;
    }
}

.arrow--disabled {
    fill: 'disabled';
}
