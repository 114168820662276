.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;

     border-radius: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: flex;
  
    width: 100%;
    height: 100%;
    object-fit: cover;
 
  }
  
  /* new styles added */
  .swiper-button {
    display: flex;
    position: absolute;
    bottom: -5em;
    z-index: 10;
    cursor: pointer;
  }
  .swiper-button svg {
    width: 1rem;
    height: 1rem;
  }
  .image-swiper-button-prev {

    border-radius: 50px;
    padding:5px;
    color: white;
    border: solid 1px white ;
    right: 45px;


  }
  .image-swiper-button-next {
    border-radius: 50px;
    padding:5px;
    color: white;
    border: solid 1px white ;
    right: 5px;
   
  }
  .swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  /* Pagination styles */
.swiper-pagination {

  // position: absolute;
  // bottom: -100px;
  // left: 50%;
  // transform: translateX(-50%);
}

.swiper-pagination-bullet {
  
  width: 10px;
  height: 10px;
  background-color: #5b5b69; /* Change this to the desired color */
  opacity: 0.5;
  border-radius: 50%;
  margin: 0 5px; /* Adjust the spacing between bullets */
  cursor: pointer;
}
.swiper-scrollbar-drag {

  height: 100%;
  width: 100%;
  position: relative;
  background: var(--swiper-scrollbar-drag-bg-color, #06C8FD80);
  border-radius: var(--swiper-scrollbar-border-radius, 10px);

}
.swiper-pagination-bullet-active {
  background-color: #06C8FD; /* Change this to the desired active color */
  opacity: 1;
}