@import "./app/styles/scss/styles.scss";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif !important;
  min-height: 100vh;

  position: relative;
  font-size: 1rem;
  // background-color: #252525;
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollable-element::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #272626;
}

body::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

html {
  scroll-behavior: smooth;
}
